import {
  ExecutionAlreadyClaimedException,
  ExecutionNotFoundException,
  InvalidClaimException,
  InvalidExecutionStatusException,
  InvalidJobInputException,
  InvalidProviderException,
  JobNotFoundException,
  TenantNotFoundException,
  UnsupportedTenantTypeException,
  SchemaNotFoundException,
} from "./exceptions";
import {
  IJobServiceException,
  JobServiceException,
} from "./JobServiceException";
import { plainToInstance } from "class-transformer";
import { InvalidTenantStateException } from "./exceptions/InvalidTenantStateException";

const transform = (
  constructor: any,
  data: IJobServiceException,
): JobServiceException => {
  const message = JSON.stringify(data);
  return plainToInstance(constructor, { ...data, message });
};

export const createJobServiceException = (
  data: IJobServiceException,
): JobServiceException => {
  if (!data.name || !data.statusCode || !data.description) {
    throw new Error(
      (data as any).message ??
        `Invalid JobServiceException data: ${JSON.stringify(data)}`,
    );
  }

  switch (data.name) {
    case "SchemaNotFoundException":
      return transform(SchemaNotFoundException, data);
    case "ExecutionNotFoundException":
      return transform(ExecutionNotFoundException, data);
    case "InvalidJobInputException":
      return transform(InvalidJobInputException, data);
    case "JobNotFoundException":
      return transform(JobNotFoundException, data);
    case "TenantNotFoundException":
      return transform(TenantNotFoundException, data);
    case "UnsupportedTenantTypeException":
      return transform(UnsupportedTenantTypeException, data);
    case "ExecutionAlreadyClaimedException":
      return transform(ExecutionAlreadyClaimedException, data);
    case "InvalidExecutionStatusException":
      return transform(InvalidExecutionStatusException, data);
    case "InvalidProviderException":
      return transform(InvalidProviderException, data);
    case "InvalidTenantStateException":
      return transform(InvalidTenantStateException, data);
    case "InvalidClaimException":
      return transform(InvalidClaimException, data);
    default:
      throw new Error(`Unsupported JobServiceException type: ${data.name}`);
  }
};

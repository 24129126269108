import { JobServiceException } from "../JobServiceException";

export class SchemaNotFoundException extends JobServiceException {
  public constructor(domain: string, additionalData?: object) {
    super(
      404,
      "SchemaNotFoundException",
      `Schema with domain ${domain} not found!`,
      {
        ...additionalData,
        domain,
      },
    );
  }
}
